import React, { useState } from 'react';
import './Modal.css'

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  onAddPlayers: (players: string[]) => void;
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, onAddPlayers }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddPlayers = () => {
    const players = inputValue.split(',').map(player => player.trim()).filter(player => player !== '');
    onAddPlayers(players);
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Add Players</h2>
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value.toUpperCase())}
          placeholder="Enter player names separated by commas"
        />
        <div className="modal-actions">
          <button className="btn" onClick={handleAddPlayers}>Add Players</button>
          <button className="btn" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
