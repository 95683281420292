import React, { useEffect, useState } from 'react';
import Modal from './component/Modal';
import './App.css';
import useLocalStorage from './hooks/useLocalStorage';
import ScaleLoader from "react-spinners/ScaleLoader";

interface Round {
  [playerName: string]: number;
}

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [players, setPlayers] = useLocalStorage<string[]>('players', []);
  const [rounds, setRounds] = useLocalStorage<Round[]>('rounds', []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gameCreated, setGameCreated] = useState(false);
  const [startingScore, setStartingScore] = useLocalStorage<number>('startingScore', 0);
  const createScoreboard = "Create Scoreboard";
  const editPlayers = "Edit Players";

  useEffect(() => {
    if (players.length > 0) {
      setGameCreated(true)
    } else {
      setGameCreated(false)
    }
    setIsLoading(false)
  }, [players]);

  const addPlayers = (newPlayers: string[]) => {
    setPlayers(prev => Array.from(new Set([...prev, ...newPlayers])));
  };

  const addRound = () => {
    const newRound = players.reduce((acc: Round, player) => {
      acc[player] = startingScore; // Use starting score from state
      return acc;
    }, {});
    setRounds(prev => [...prev, newRound]);
  };

  const updateScore = (roundIndex: number, playerName: string, score: number) => {
    const updatedRounds = rounds.map((round, index) => {
      if (index === roundIndex) {
        return { ...round, [playerName]: score };
      }
      return round;
    });
    setRounds(updatedRounds);
  };

  const startNewGame = () => {
    const confirmNewGame = window.confirm("Are you sure you want to start a new game? This will erase the current game data.");
    if (confirmNewGame) {
      setPlayers([]);
      setRounds([]);
    }
  }

  return (

    <div>
      {isLoading ?
        <div className='spinner-container'>
          <ScaleLoader loading={isLoading} color="#02A38A" />
        </div>
        :
        <>
          <div className='btn-container'>
            {gameCreated &&
              <button className="button" onClick={startNewGame}>New Game</button>
            }
            <button className="button" onClick={() => setIsModalVisible(true)}>
              {gameCreated ? editPlayers : createScoreboard}
            </button>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} onAddPlayers={addPlayers} />
            {gameCreated && (
              <div className='starting-number-container'>
                <label htmlFor="startingScore" style={{ marginRight: '8px' }}>Starting Number:</label>
                <input
                  id="startingScore"
                  type="number"
                  value={startingScore}
                  onChange={(e) => setStartingScore(parseInt(e.target.value) || 0)}
                  placeholder="Starting Score"
                />
              </div>
            )}

          </div>

          {gameCreated &&
            <>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    {players.map(player => <th key={player}>{player}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {rounds.map((round, roundIndex) => (
                    <tr key={roundIndex}>
                      <td>{roundIndex + 1}</td>
                      {players.map(player => (
                        <td key={player}>
                          <input
                            type="number"
                            value={round[player] ?? ''}
                            onChange={(e) => updateScore(roundIndex, player, parseInt(e.target.value) || 0)}
                            placeholder="Score"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    {players.map(player => {
                      const totalScore = rounds.reduce((acc, round) => acc + (round[player] || 0), 0);
                      return <td key={player + "-total"}>{totalScore}</td>;
                    })}
                  </tr>
                </tbody>
              </table>
              <div className='btn-container'>
                <button className="button" onClick={addRound}>Add Round</button>
              </div>
            </>
          }
        </>
      }
    </div>
  );
};

export default App;
